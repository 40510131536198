import preset1 from '../../../assets/media/collections-portfolio-presets/1.png';
import preset2 from '../../../assets/media/collections-portfolio-presets/2.png';
import preset3 from '../../../assets/media/collections-portfolio-presets/3.png';
import preset4 from '../../../assets/media/collections-portfolio-presets/4.png';
import preset5 from '../../../assets/media/collections-portfolio-presets/5.png';
import preset6 from '../../../assets/media/collections-portfolio-presets/6.png';
import preset7 from '../../../assets/media/collections-portfolio-presets/7.png';
import preset8 from '../../../assets/media/collections-portfolio-presets/8.png';
import preset9 from '../../../assets/media/collections-portfolio-presets/9.png';
import preset10 from '../../../assets/media/collections-portfolio-presets/10.png';
import preset11 from '../../../assets/media/collections-portfolio-presets/11.png';
import preset12 from '../../../assets/media/collections-portfolio-presets/12.png';
import preset13 from '../../../assets/media/collections-portfolio-presets/13.png';
import preset14 from '../../../assets/media/collections-portfolio-presets/14.png';
import preset15 from '../../../assets/media/collections-portfolio-presets/15.png';
import preset16 from '../../../assets/media/collections-portfolio-presets/16.png';
import preset17 from '../../../assets/media/collections-portfolio-presets/17.png';
import preset18 from '../../../assets/media/collections-portfolio-presets/18.png';
import preset19 from '../../../assets/media/collections-portfolio-presets/19.png';
import preset20 from '../../../assets/media/collections-portfolio-presets/20.png';
import preset21 from '../../../assets/media/collections-portfolio-presets/21.png';
import preset22 from '../../../assets/media/collections-portfolio-presets/22.png';
import preset23 from '../../../assets/media/collections-portfolio-presets/23.png';
import preset24 from '../../../assets/media/collections-portfolio-presets/24.png';

import { CollectionWidgetComponentIds } from '../../collectionWidget/config/constants';

export enum PortfolioPageWidgetComponentIds {
  Root = '#portfolioPageWidgetRoot',
  MultiStateWidget = '#collectionMsbWidget1',
}

export enum PortfolioPageWidgetEvents { }

export const PortfolioPageWidgetTabsArray = [
  {
    label: 'Portfolio_Design_Panel_Title',
    roles: [CollectionWidgetComponentIds.Title],
    skipOnEditorX: true,
  },
  {
    label: 'Portfolio_Design_Panel_Description',
    roles: [CollectionWidgetComponentIds.Description],
    skipOnEditorX: true,
  },
  // {
  //   label: 'Portfolio_Design_Panel_Projects_Gallery',
  //   roles: [CollectionWidgetComponentIds.Gallery],
  // },
  {
    label: 'Portfolio_Design_Panel_Page_Background',
    roles: [PortfolioPageWidgetComponentIds.Root],
  },
];

export const PortfolioPageWidgetPresets = [
  {
    id: 'externalPreset-variants-lcx0pjfc',
    connectedMobilePresetID: 'variants-lcx0z393',
    src: preset1,
    layout: {
      width: '114px',
      height: '102px',
    },
  },
  {
    id: 'externalPreset-variants-lcx10cj9',
    connectedMobilePresetID: 'variants-lcx11hw0',
    src: preset2,
    layout: {
      width: '114px',
      height: '102px',
    },
  },
  {
    id: 'externalPreset-variants-lcx2oty1',
    connectedMobilePresetID: 'variants-lcx2tw18',
    src: preset3,
    layout: {
      width: '114px',
      height: '127px',
    },
  },
  {
    id: 'externalPreset-variants-lcx39wso',
    connectedMobilePresetID: 'variants-lcx3b4cc',
    src: preset4,
    layout: {
      width: '114px',
      height: '127px',
    },
  },
  {
    id: 'externalPreset-variants-lcx2mz9w',
    connectedMobilePresetID: 'variants-lcx2nvz6',
    src: preset5,
    layout: {
      width: '114px',
      height: '106px',
    },
  },
  {
    id: 'externalPreset-variants-lcx2vjv5',
    connectedMobilePresetID: 'variants-lcx2wl9q',
    src: preset6,
    layout: {
      width: '114px',
      height: '106px',
    },
  },
  {
    id: 'externalPreset-variants-lcx3o6hc',
    connectedMobilePresetID: 'variants-lcx3pog9',
    src: preset7,
    layout: {
      width: '114px',
      height: '98px',
    },
  },
  {
    id: 'externalPreset-variants-lcx2xpur',
    connectedMobilePresetID: 'variants-lcx2ysz2',
    src: preset8,
    layout: {
      width: '114px',
      height: '98px',
    },
  },
  {
    id: 'externalPreset-variants-lcx3e613',
    connectedMobilePresetID: 'variants-lcx3fh5u',
    src: preset9,
    layout: {
      width: '114px',
      height: '98px',
    },
  },
  {
    id: 'externalPreset-variants-lcx3lp4j',
    connectedMobilePresetID: 'variants-lcx3mydl',
    src: preset10,
    layout: {
      width: '114px',
      height: '98px',
    },
  },
  {
    id: 'externalPreset-variants-lcx49otg',
    connectedMobilePresetID: 'variants-lcx4czek',
    src: preset11,
    layout: {
      width: '114px',
      height: '97px',
    },
  },
  {
    id: 'externalPreset-variants-lcx3wkjh',
    connectedMobilePresetID: 'variants-lcx3xv4j',
    src: preset12,
    layout: {
      width: '114px',
      height: '97px',
    },
  },
  {
    id: 'externalPreset-variants-lcx3tyba',
    connectedMobilePresetID: 'variants-lcx3va3d',
    src: preset13,
    layout: {
      width: '114px',
      height: '113px',
    },
  },
  {
    id: 'externalPreset-variants-lcx866hl',
    connectedMobilePresetID: 'variants-lcx87sv9',
    src: preset14,
    layout: {
      width: '114px',
      height: '113px',
    },
  },
  {
    id: 'externalPreset-variants-lcx7zdj7',
    connectedMobilePresetID: 'variants-lcx82cdm',
    src: preset15,
    layout: {
      width: '114px',
      height: '85px',
    },
  },
  {
    id: 'externalPreset-variants-lcx46ocb',
    connectedMobilePresetID: 'variants-lcx48bby',
    src: preset16,
    layout: {
      width: '114px',
      height: '85px',
    },
  },
  {
    id: 'externalPreset-variants-lcx37ssj',
    connectedMobilePresetID: 'variants-lcx38p21',
    src: preset17,
    layout: {
      width: '114px',
      height: '95px',
    },
  },
  {
    id: 'externalPreset-variants-lcx30vzu',
    connectedMobilePresetID: 'variants-lcx31wi4',
    src: preset18,
    layout: {
      width: '114px',
      height: '95px',
    },
  },
  {
    id: 'externalPreset-variants-lcx3zthz',
    connectedMobilePresetID: 'variants-lcx416ar',
    src: preset19,
    layout: {
      width: '114px',
      height: '106px',
    },
  },
  {
    id: 'externalPreset-variants-lcx3gquk',
    connectedMobilePresetID: 'variants-lcx3hufz',
    src: preset20,
    layout: {
      width: '114px',
      height: '106px',
    },
  },
  {
    id: 'externalPreset-variants-lcx4eft0',
    connectedMobilePresetID: 'variants-lcx4fuow',
    src: preset21,
    layout: {
      width: '114px',
      height: '101px',
    },
  },
  {
    id: 'externalPreset-variants-lcx12n3p',
    connectedMobilePresetID: 'variants-lcx2hg5i',
    src: preset22,
    layout: {
      width: '114px',
      height: '101px',
    },
  },
  {
    id: 'externalPreset-variants-lcx4hg7w',
    connectedMobilePresetID: 'variants-lcx4j4bw',
    src: preset23,
    layout: {
      width: '114px',
      height: '106px',
    },
  },
  {
    id: 'externalPreset-variants-lcx3j63z',
    connectedMobilePresetID: 'variants-lcx3k8i4',
    src: preset24,
    layout: {
      width: '114px',
      height: '106px',
    },
  },
];
